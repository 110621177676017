import React from 'react'
import PageContainer from 'src/components/layout/PageContainer'
import type {GetStaticProps} from 'next'
import {
  makeSubscriptionProps,
  useFrontPageContentSubscription,
} from 'src/api/datocms/utils'
import type {QueryListenerOptions} from 'react-datocms'
import type {FrontPage} from 'src/api/datocms/types/FrontPage'
import {frontPageContentQuery, pageQuery} from 'src/api/datocms/queries'
import {DatocmsContent} from 'src/components/content/DatocmsContent'
import {Config} from 'src/config'
import NotFound from 'src/components/layout/NotFound'
import {DEFAULT_REVALIDATE_TIME} from 'src/const'

interface Props {
  subscription: QueryListenerOptions<FrontPage, never>
}

export const getStaticProps: GetStaticProps = async ({preview = false}) => ({
  props: {
    pageSubscription: await makeSubscriptionProps(preview, pageQuery, {}, true),
    subscription: await makeSubscriptionProps(
      preview,
      frontPageContentQuery,
      {},
      true,
    ),
    preview,
  },
  revalidate: DEFAULT_REVALIDATE_TIME,
})

export default function Home({subscription}: Props): JSX.Element {
  const {data} = useFrontPageContentSubscription(subscription)
  const heading = data?.frontPage?.heading
    ? `${Config.title} | ${data?.frontPage?.heading}`
    : Config.title
  const content = data?.frontPage?.content ?? []

  return (
    <PageContainer title={heading}>
      {!content ? <NotFound /> : <DatocmsContent content={content} />}
    </PageContainer>
  )
}
