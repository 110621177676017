import React from 'react'
import {useRouter} from 'next/router'

import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {ifProp, palette} from 'styled-tools'
import {paths} from 'src/utils/paths'
import {Button} from '../buttons'
import {H1, Title5} from '../typography'

const NotFoundWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  position: relative;
`

interface TitileProps {
  errorPage?: boolean
}
const TextContainer = styled(Container)<TitileProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  ${Title5} {
    color: ${ifProp({errorPage: true}, palette('error'), palette('secondary'))};
    text-align: center;
    margin: 40px 0;
  }
`

interface Props {
  title?: string
  text?: string
  errorPage?: boolean
}

export default function NotFound({
  title,
  text,
  errorPage = false,
}: Props): JSX.Element {
  const route = useRouter()

  return (
    <NotFoundWrapper>
      <TextContainer errorPage={errorPage}>
        <H1>{title || 'Eitthvað fór úrskeiðis'}</H1>
        <Title5>{text || 'Síðan fannst ekki (404)'}</Title5>
        <Button onClick={() => route.push(paths.home)}>Fara á forsíðu</Button>
      </TextContainer>
    </NotFoundWrapper>
  )
}
